










import { SfLoader } from '@storefront-ui/vue';
import {
  defineComponent,
  useRoute,
  ssrRef,
  useContext,
  useFetch,
} from '@nuxtjs/composition-api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { useUrlResolver } from '~/composables/useUrlResolver';
import cacheControl from '~/helpers/cacheControl';
import { useContent } from '@gemini-vsf/composables';
import { RootComponent } from '~/types/types';
import { useI18n } from '~/helpers/hooks/usei18n';

function isRedirect(redirectCode: number | undefined): boolean {
  const redirectCodes = new Set().add(301).add(302);
  return redirectCodes.has(redirectCode);
}

export default defineComponent({
  name: 'RoutePage',
  components: {
    SfLoader,
    ProductPage: () => import('~/pages/Product.vue'),
    CategoryPage: () => import('~/pages/Category.vue'),
    CmsPage: () => import('~/pages/Page.vue'),
    Error: () => import('~/layouts/error.vue'),
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  setup() {
    const CacheTagPrefixUrl = 'U'; // TODO move to cache lib
    const { error: nuxtError, redirect, app } = useContext();
    const route = useRoute();
    const {
      params: { path },
    } = route.value;
    const {
      result,
      search: resolveUrl,
      loading: urlResolverLoading,
    } = useUrlResolver();
    const rootComponent = ssrRef<RootComponent | null>(null);
    const { addTags } = useCache();
    const { page: contentPage, loadContent } = useContent(`cmsPage-${path}`);
    const { locale } = useI18n();
    const cmsIdentifier = `${locale}/${path}`;

    useFetch(async () => {
      rootComponent.value = { name: '' };
      await resolveUrl();

      const component: RootComponent = { name: '' };
      const urlRewrite = result?.value;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const shouldRedirect = isRedirect(urlRewrite?.redirectCode);

      if (
        shouldRedirect &&
        urlRewrite?.urlPath &&
        urlRewrite?.urlPath !== path
      ) {
        const regx = /^(?:[a-z]+:)?\/\//i;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const redirectPath = regx.test(urlRewrite.urlPath)
          ? urlRewrite.urlPath
          : app.localePath(`/${urlRewrite.urlPath.replace(/^(\/)/, '')}`);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        redirect(urlRewrite.redirectCode, redirectPath);
        return;
      }

      switch (urlRewrite?.entityCode) {
        case 'Product': {
          component.name = 'ProductPage';
          component.props = {
            productId: urlRewrite?.entityId,
          };
          break;
        }
        case 'ProductList': {
          component.name = 'CategoryPage';
          component.props = {
            categoryId: urlRewrite?.entityId,
          };
          break;
        }
        default: {
          await loadContent({
            identifier: cmsIdentifier,
          });
          if (contentPage?.value?.content) {
            component.name = 'CmsPage';
            component.props = {
              contentPage: contentPage?.value,
            };
          }
        }
      }

      if (!component?.name || component.name === 'Error') {
        nuxtError({ statusCode: 404 });
      } else {
        // avoid caching cms, the cache doesn't work with nuxtError
        // eslint-disable-next-line no-lonely-if
        if (component.name !== 'CmsPage') {
          addTags([
            { prefix: CacheTagPrefix.View, value: 'urlrewrite' },
            { prefix: CacheTagPrefixUrl, value: urlRewrite?.entityId },
          ]);
        }
      }
      rootComponent.value = component;
    });

    return {
      rootComponent,
      loading: urlResolverLoading,
    };
  },
});
